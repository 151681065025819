.infopage{
    background-image: url(./assets/inforbg.jpg);
    color: #fff;
}

.information{
    padding: 2rem;
    text-align: center;
}

.box1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 100px 0;

}

#nylon1{
    width: 400px;
    height: 400px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left: 120px;
}

#nylon2{
    width: 400px;
    height: 400px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.nylon-text{
    text-align: left;
    font-size: 25px;
    max-width: 500px;
    transform: translateX(-250px);
    text-shadow: #fff 2px 8px 20px;
}

.information2{
    padding: 2rem;
    text-align: center;
}

.box2{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 100px 0;
}

#environment1{
    width: 400px;
    height: 400px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

}

#environment2{
    width: 400px;
    height: 400px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-right: 120px;
}

.environment-text{
    text-align: left;
    font-size: 25px;
    max-width: 500px;
    transform: translateX(250px);
    text-shadow: #fff 2px 8px 20px;
    padding-top: 20px;
}




@media only screen and (max-width: 1440px){
    .information{
        padding: 2rem;
        text-align: center;
        background-image: url(./assets/inforbg.jpg);
        color: #fff;
    }
    
    .box1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #nylon1{
        width: 400px;
        height: 400px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        transform: translateX(-60px);

    }
    
    #nylon2{
        width: 400px;
        height: 400px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        transform: translateX(-60px);
    }
    
    .nylon-text{

        font-size: 30px;
        max-width: 700px;
        transform: translateX(21rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }
    
    .information2{
        padding: 2rem;
        text-align: center;
    }
    
    .box2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #environment1{
        width: 400px;
        height: 400px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        transform: translateX(60px);
    
    }
    
    #environment2{
        width: 400px;
        height: 400px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        transform: translateX(60px);
    }
    
    .environment-text{
        font-size: 30px;
        max-width: 700px;
        transform: translateX(21rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }
}


@media only screen and (max-width: 1366px){
    .nylon-text{

        font-size: 30px;
        max-width: 700px;
        transform: translateX(18rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }

    .environment-text{
        font-size: 30px;
        max-width: 700px;
        transform: translateX(18rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }
}


@media only screen and (max-width: 1280px){
    .information{
        padding: 2rem;
        text-align: center;
        background-image: url(./assets/inforbg.jpg);
        color: #fff;
    }
    
    .box1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #nylon1{
        width: 400px;
        height: 400px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        transform: translateX(-60px);

    }
    
    #nylon2{
        width: 400px;
        height: 400px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        transform: translateX(-60px);
    }
    
    .nylon-text{

        font-size: 30px;
        max-width: 700px;
        transform: translateX(16rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }
    
    .information2{
        padding: 2rem;
        text-align: center;
    }
    
    .box2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #environment1{
        width: 400px;
        height: 400px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        transform: translateX(60px);
    
    }
    
    #environment2{
        width: 400px;
        height: 400px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        transform: translateX(60px);
    }
    
    .environment-text{
        font-size: 30px;
        max-width: 700px;
        transform: translateX(16rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }
} 

@media only screen and (max-width: 991px){
    .information{
        padding: 2rem;
        text-align: center;
        background-image: url(./assets/inforbg.jpg);
        color: #fff;
    }
    
    .box1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #nylon1{
        width: 300px;
        height: 300px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        transform: translateX(-60px);

    }
    
    #nylon2{
        width: 300px;
        height: 300px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        transform: translateX(-60px);
    }
    
    .nylon-text{

        font-size: 25px;
        max-width: 600px;
        transform: translateX(10rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }
    
    .information2{
        padding: 2rem;
        text-align: center;
        box-shadow: 0px 15px 20px 10px;
    }
    
    .box2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #environment1{
        width: 300px;
        height: 300px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        transform: translateX(60px);

    
    }
    
    #environment2{
        width: 300px;
        height: 300px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        transform: translateX(60px);
    }
    
    .environment-text{
        font-size: 25px;
        max-width: 600px;
        transform: translateX(10rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }
}

@media only screen and (max-width: 768px){

    .information{
        padding: 2rem;
        text-align: center;
        background-image: url(./assets/inforbg.jpg);
        color: #fff;
    }
    
    .box1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #nylon1{
        width: 250px;
        height: 250px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        transform: translateX(-60px);

    }
    
    #nylon2{
        width: 250px;
        height: 250px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        transform: translateX(-60px);
    }
    
    .nylon-text{
        font-size: 25px;
        max-width: 500px;
        transform: translateX(100px);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }
    
    .information2{
        padding: 2rem;
        text-align: center;
    }
    
    .box2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #environment1{
        width: 250px;
        height: 250px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        transform: translateX(60px);

    
    }
    
    #environment2{
        width: 250px;
        height: 250px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        transform: translateX(60px);
    }
    
    .environment-text{
        font-size: 25px;
        max-width: 500px;
        transform: translateX(100px);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }


}

@media only screen and (max-width: 576px){

    .infopage{
        background-image: url(./assets/inforbg.jpg);
        color: #fff;
    }

    .information{
        padding: 2rem;
        text-align: center;

    }

    .box1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;     
    }
    
    #nylon1{
        width: 250px;
        height: 250px;
        border-radius: 20px;
   

    }
    
    #nylon2{
        width: 250px;
        height: 250px;
        border-radius: 20px;
        transform: translateX(-0.1rem);
    }
    
    .nylon-text{

        font-size: 20px;
        max-width: 400px;
        transform: translateX(3.5rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }
    
    .information2{
        padding: 2rem;
        text-align: center;
    }
    
    .box2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #environment1{
        width: 250px;
        height: 250px;
        border-radius: 20px;
        transform: translateX(-0.5rem);

    
    }
    
    #environment2{
        width: 250px;
        height: 250px;
        border-radius: 20px;
        transform: translateX(3.5rem);
    }
    
    .environment-text{
        font-size: 20px;
        max-width: 400px;
        transform: translateX(2.5rem);
        text-shadow: #fff 2px 8px 20px;
        text-align: center;
        padding-top: 20px;
    }

    @media only screen and (max-width: 414px){
        .infopage{
            background-image: url(./assets/inforbg.jpg);
            color: #fff;
        }
    
        .information{
            padding: 2rem;
            text-align: center;
    
        }
    
        .box1{
            display: flex;
            flex-direction: column;
            justify-content: space-between;     
        }
        
        #nylon1{
            width: 250px;
            height: 250px;
            border-radius: 20px;
            transform: translateX(-4.4rem);
        }
        
        #nylon2{
            width: 250px;
            height: 250px;
            border-radius: 20px;
            transform: translateX(-0.1rem);
        }
        
        .nylon-text{
    
            font-size: 20px;
            max-width: 400px;
            transform: translateX(0.5rem);
            text-shadow: #fff 2px 8px 20px;
            text-align: center;
            padding-top: 20px;
        }
        
        .information2{
            padding: 2rem;
            text-align: center;
        }
        
        .box2{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        
        #environment1{
            width: 250px;
            height: 250px;
            border-radius: 20px;
            transform: translateX(0.5rem);
    
        
        }
        
        #environment2{
            width: 250px;
            height: 250px;
            border-radius: 20px;
            transform: translateX(3.5rem);
        }
        
        .environment-text{
            font-size: 20px;
            max-width: 400px;
            transform: translateX(1rem);
            text-shadow: #fff 2px 8px 20px;
            text-align: center;
            padding-top: 20px;
        }
    }
}