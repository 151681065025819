@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,700&family=Kumbh+Sans:wght@400;700&family=Poppins:wght@100;400;900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Kumbh Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}
