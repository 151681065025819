
.navbar{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 999;
}

.icon{
color: black;
padding-left: 2rem;
font-size: 2rem;
}

.links{
    list-style-type: none ;  
    display: flex;
    font-size: 1.5rem;
    padding-right: 20px;
    margin: 0;
}

.nav-links{
    color:black;
    text-decoration: none;
    padding: 0 2rem;
    position: relative;
}
.active .nav-links,
.nav-links:hover{
    color:rgb(30, 133, 180) ;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
}

.nav-cart:hover{
    color: #fff;
    border-radius: 50%;
    background-color:rgb(30, 133, 180) ;
    transition: all 0.3s ease-in-out;
}

.active .nav-links::after,
.nav-links:hover::after{
    content: '';
    background-color: rgb(30, 133, 180);
    width: 50%;
    height: 2px;
    position: absolute;
    bottom: -4px;
    left: 25%;
}






@media (max-width: 576px) {
    .navbar{
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
        width: 95%;
        height: 80px;
        border-radius: 13px;
        position: fixed;
        top: 20px;
        left: 50%;
        transform: translate(-50%);
        z-index: 999;
    }
    
    .icon{
    color: black;
    padding-left: 1rem;
    font-size: 2rem;
    }
    
    .links{
        list-style-type: none ;  
        display: flex;
        font-size: 1.5rem;
        padding-right: 1rem;
        margin: 0;
    }
    
    .nav-links{
        color:black;
        text-decoration: none;
        padding: 0 1rem;
        position: relative;
    }

    @media (max-width: 500px) {
        .navbar{
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
            width: 95%;
            height: 80px;
            border-radius: 13px;
            position: fixed;
            top: 20px;
            left: 50%;
            transform: translate(-50%);
            z-index: 999;
            max-width: 300;
        }
        
        .icon{
        color: black;
        padding-left: 1rem;
        font-size: 1.5rem;
        }
        
        .links{
            list-style-type: none ;  
            display: flex;
            font-size: 1rem;
            padding-right: 0.5rem;
            margin: 0;
        }
        
        .nav-links{
            color:black;
            text-decoration: none;
            padding: 0 0.5rem;
            position: relative;
        }
    }
}