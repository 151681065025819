.cards{
}

.mb-3{
    background-color: rgba(59, 58, 58, 0.39);
    max-width: 1100px;
    max-height: 800px;
    left: 50%;
    transform: translate(-50%);
    margin: 4rem;
    color: #fff;
    box-shadow: 10px 4px 15px 4px;
    display: flex;
    flex-direction: row;
}


.cart-btn{
    width: 140px;
    height: 50px;
    font-size: 20px;
    border-radius: 12px;
    outline: none;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.cart-btn a{
    text-decoration: none;
    color: black;
}



.cart-btn:hover{
    color: #fff;
    background-color: rgb(71, 132, 253);
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1200px){
    .mb-3{
        background-color: rgba(59, 58, 58, 0.39);
        width: 600px;
        max-height: 600px;
        left: 50%;
        transform: translate(-65%);
        margin: 4rem;
        color: #fff;
        box-shadow: 10px 4px 15px 4px;
        display: flex;
        flex-direction: row;
        border:1px;
    }
    .mb-3 img{
        width: 400px;
    }
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 768px){
    .mb-3{
        background-color: rgba(59, 58, 58, 0.39);
        width: 400px;
        max-height: 600px;
        left: 50%;
        transform: translate();
        margin: 4rem;
        color: #fff;
        box-shadow: 10px 4px 15px 4px;
        display: flex;
        flex-direction: row;
        border:1px;
    }
    .mb-3 img{
        width: 200px;
    }
}
@media only screen and (max-width: 576px){
}