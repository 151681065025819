.proudct-hero{
    width: 100%;
    height: 70vh;
    position: relative;
}

#bgvideo2{
    width: 100%;
    height: 70vh;
    object-fit: fill;
}

.proudct-hero-text{
    position: absolute;  
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.proudct-hero h1{
    font-size: 3rem;
    font-weight: 800;
    background-color: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    justify-content: center;
    white-space: nowrap;
}

.proudct-hero h3{
    font-size: 2rem;
    font-weight: 800;
    background-color: #ffffffbd;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    justify-content: center;
    text-align: center;
}
@media only screen and (max-width: 1200px){
    .proudct-hero h1{
        font-size: 2rem;
        font-weight: 800;
        max-width: 1000px;
        white-space: nowrap;
        background-color: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        justify-content: center;
    }
}
@media only screen and (max-width: 991px) {

}
@media only screen and (max-width: 768px){
.proudct-hero h1{
    font-size: 1.6rem;
    font-weight: 600;
    max-width: 700px;
    background-color: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    justify-content: center;
}

.proudct-hero h3{
    font-size: 1.2rem;
    font-weight: 800;
    background-color: #ffffffbd;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    justify-content: center;
    text-align: center;
}
}
@media only screen and (max-width: 576px){
    

    
    .proudct-hero h1{
        font-size: 1rem;
        font-weight: 600;
        max-width: 500px;
        background-color: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        justify-content: center;
    }
    
    .proudct-hero h3{
        font-size: 0.8rem;
        font-weight: 800;
        background-color: #ffffffbd;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        justify-content: center;
        text-align: center;
    }
}